import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { RichText } from "prismic-reactjs";
import { graphql } from "gatsby";
import styled from "@emotion/styled";
import colors from "styles/colors";
import dimensions from "styles/dimensions";
import Button from "components/_ui/Button";
import About from "components/About";
import Layout from "components/Layout";
import ArticleCard from "components/ArticleCard";
import PostCard from "components/PostCard";
import ExternalCard from "components/ExternalCard";
import MailchimpForm from "components/MailchimpForm";
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Hero = styled("div")`
    padding-top: 2.5em;
    padding-bottom: 3em;
    margin-bottom: 6em;
    max-width: 830px;

    @media(max-width:${dimensions.maxwidthMobile}px) {
       margin-bottom: 3em;
    }

    h1 {
        margin-bottom: 1em;

        a {
            text-decoration: none;
            transition: all 100ms ease-in-out;

            &:nth-of-type(1) { color: ${colors.blue500}; }
            &:nth-of-type(2) { color: ${colors.orange500}; }
            &:nth-of-type(3) { color: ${colors.purple500}; }
            &:nth-of-type(4) { color: ${colors.green500}; }
            &:nth-of-type(5) { color: ${colors.teal500}; }

            &:hover {
                cursor: pointer;
                transition: all 100ms ease-in-out;

                &:nth-of-type(1) { color: ${colors.blue600};    background-color: ${colors.blue200};}
                &:nth-of-type(2) { color: ${colors.orange600};  background-color: ${colors.orange200};}
                &:nth-of-type(3) { color: ${colors.purple600};  background-color: ${colors.purple200};}
                &:nth-of-type(4) { color: ${colors.green600};   background-color: ${colors.green200};}
                &:nth-of-type(5) { color: ${colors.teal600};    background-color: ${colors.teal200};}

            }
        }
    }
`

const SectionTitle = styled("h2")`
    margin: 3em auto 1em auto;
`

const Section = styled("div")`
    margin-bottom: 10em;
    display: flex;
    flex-direction: column;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 4em;
    }

    &:last-of-type {
        margin-bottom: 0;
    }

    .MailchimpForm h3 {
        padding-top: 1em;
    }
`

const CodeGrid = styled("div")`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2.5em;

    @media(max-width: 1050px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5em;
    }

    @media(max-width: ${dimensions.maxwidthMobile}px) {
        grid-template-columns: 1fr;
        grid-gap: 2.5em;
    }
`

const RenderBody = ({ home, articles, posts, projects, externals, meta }) => (
    <>
        <Helmet
            title={`Home | Carolyn Stransky`}
            htmlAttributes={{ lang: "en" }}
            link={[
                {
                    rel: `canonical`,
                    href: `https://workwithcarolyn.com`
                }
            ]}
            meta={[
                {
                    name: `description`,
                    content: meta.description,
                },
                {
                    property: `og:title`,
                    content: meta.title,
                },
                {
                    property: `og:description`,
                    content: meta.description,
                },
                {
                    name: `og:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: meta.author,
                },
                {
                    name: `twitter:title`,
                    content: meta.title,
                },
                {
                    name: `twitter:description`,
                    content: meta.description,
                },
                {
                    name: `twitter:image`,
                    content: `https://user-images.githubusercontent.com/26869552/80156613-a8c83a00-85c4-11ea-98a6-45ecad014bcd.png`,
                }
            ].concat(meta)}
        />
        <Hero>
            <>
                {RichText.render(home.hero_title)}
            </>
            <OutboundLink href={home.hero_button_link.url}
               target="_blank" rel="noopener noreferrer">
                <Button>
                    {RichText.render(home.hero_button_text)}
                </Button>
            </OutboundLink>
        </Hero>
        <SectionTitle>
            Featured work
        </SectionTitle>
        <Section>
        <ArticleCard
            category={articles[8].node.article_category}
            title={articles[8].node.article_title}
            description={articles[8].node.article_preview_description}
            thumbnail={articles[8].node.article_preview_thumbnail}
            external_link={articles[8].node.article_external_link}
            uid={articles[8].node._meta.uid}
        />
        <ArticleCard
            category={articles[3].node.article_category}
            title={articles[3].node.article_title}
            description={articles[3].node.article_preview_description}
            thumbnail={articles[3].node.article_preview_thumbnail}
            external_link={articles[3].node.article_external_link}
            uid={articles[3].node._meta.uid}
        />
        <CodeGrid>
            <PostCard 
                category={posts[6].node.post_category}
                title={posts[6].node.post_title}
                description={posts[6].node.post_preview_description}
                link_text={posts[6].node.post_link_text}
                uid={posts[6].node._meta.uid}
            />
            <PostCard 
                category={posts[3].node.post_category}
                title={posts[3].node.post_title}
                description={posts[3].node.post_preview_description}
                link_text={posts[3].node.post_link_text}
                uid={posts[3].node._meta.uid}
            />
            <ExternalCard 
                category={externals[1].node.external_category}
                title={externals[1].node.external_title}
                description={externals[1].node.external_preview_description}
                link_text={externals[1].node.external_link_text}
                link={externals[1].node.external_link}
                uid={externals[1].node._meta.uid}
            />
        </CodeGrid>
        </Section>
        <Section>
            {RichText.render(home.about_title)}
            <About
                bio={home.about_bio}
                socialLinks={home.about_links}
            />
        </Section>
        <Section>
            <MailchimpForm
                className="MailchimpForm" 
                align="left"
            />
        </Section>
    </>
);

export default ({ data }) => {
    //Required check for no data being returned
    const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
    const articles = data.prismic.allArticles.edges;
    const posts = data.prismic.allPosts.edges;
    const projects = data.prismic.allProjects.edges;
    const externals = data.prismic.allExternals.edges;
    const meta = data.site.siteMetadata;

    if (!doc || !articles) return null;

    return (
        <Layout>
            <RenderBody home={doc.node} articles={articles} posts={posts} projects={projects} externals={externals} meta={meta}/>
        </Layout>
    )
}

RenderBody.propTypes = {
    home: PropTypes.object.isRequired,
    articles: PropTypes.array.isRequired,
    posts: PropTypes.array.isRequired,
    projects: PropTypes.array,
    meta: PropTypes.object.isRequired,
};

export const query = graphql`
    {
        prismic {
            allHomepages {
                edges {
                    node {
                        hero_title
                        hero_button_text
                        hero_button_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        content
                        about_title
                        about_bio
                        about_links {
                            about_link
                        }
                    }
                }
            }
            allArticles(sortBy: article_post_date_DESC) {
                edges {
                    node {
                        article_title
                        article_preview_description
                        article_preview_thumbnail
                        article_category
                        article_post_date
                        article_external_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
            allPosts(sortBy: post_date_DESC) {
                edges {
                    node {
                        post_title
                        post_preview_description
                        post_category
                        post_date
                        post_link_text
                        _meta {
                            uid
                        }
                    }
                }
            }
            allProjects(sortBy: project_date_DESC) {
                edges {
                    node {
                        project_title
                        project_preview_description
                        project_category
                        project_date
                        project_link_text
                        project_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
            allExternals(sortBy: external_date_DESC) {
                edges {
                    node {
                        external_title
                        external_date
                        external_category
                        external_link_text
                        external_preview_description
                        external_link {
                            ... on PRISMIC__ExternalLink {
                                _linkType
                                url
                            }
                        }
                        _meta {
                            uid
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                description
                author
                baseOG
            }
        }
    }
`
