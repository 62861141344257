import React from "react";
import { Link } from "gatsby";
import { RichText } from "prismic-reactjs";
import styled from "@emotion/styled";
import dimensions from "styles/dimensions";
import colors from "styles/colors";
import PropTypes from "prop-types";
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ArticleCardContainer = styled(Link)`
    display: grid;
    grid-template-columns: 4fr 7fr;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    margin-bottom: 4em;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;
    max-height: 400px;

    @media(max-width:950px) {
        grid-template-columns: 4.5fr 7fr;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        grid-template-columns: 1fr;
    }

    @media(max-width:${dimensions.maxwidthMobile}px) {
        margin-bottom: 7em;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .ArticleCardAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }

        .ArticleCardContent::before {
            opacity: 0.02;
            transition: all 150ms ease-in-out;
        }

        .ArticleCardImageContainer::before {
            opacity: 0.2;
            transition: all 150ms ease-in-out;
        }
    }
`

const ArticleCardContainerExternal = styled(OutboundLink)`
    display: grid;
    grid-template-columns: 4fr 7fr;
    box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.06);
    margin-bottom: 4em;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;
    max-height: 400px;

    @media(max-width:950px) {
        grid-template-columns: 4.5fr 7fr;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        grid-template-columns: 1fr;
    }

    @media(max-width:${dimensions.maxwidthMobile}px) {
        margin-bottom: 7em;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        .ArticleCardAction {
            color: ${colors.blue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }

        .ArticleCardContent::before {
            opacity: 0.02;
            transition: all 150ms ease-in-out;
        }

        .ArticleCardImageContainer::before {
            opacity: 0.2;
            transition: all 150ms ease-in-out;
        }
    }
`

const ArticleCardContent = styled("div")`
    background: white;
    padding: 4em 3em 2.25em 3em;
    position: relative;
    max-height: 400px;

    &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: ${colors.blue500};
        mix-blend-mode: multiply;
        opacity: 0;
        transition: all 150ms ease-in-out;
    }

    @media(max-width:950px) {
        padding: 3.25em 2.5em 2em 2.5em;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        grid-row: 2;
    }
`

const ArticleCardCategory = styled("h6")`
    font-weight: 600;
    color: ${colors.grey600};
`

const ArticleCardTitle = styled("h3")`
    margin-bottom: 0.5em;
    margin-top: 0.5em;
`

const ArticleCardBlurb = styled("div")`
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 3em;

    @media(max-width:${dimensions.maxwidthTablet}px) {
        margin-bottom: 2.5em;
    }
`

const ArticleCardAction = styled("div")`
    font-weight: 600;
    text-decoration: none;
    color: currentColor;
    transition: all 150ms ease-in-out;

    span {
        margin-left: 1em;
        transform: translateX(-8px);
        display: inline-block;
        transition: transform 400ms ease-in-out;
    }
`

const ArticleCardImageContainer = styled("div")`
    background: ${colors.grey200};
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    position: relative;
    max-height: 400px;

    img {
        width: 100%;
        height: 100%;
        min-width: 0;
    }

    @media(max-width:${dimensions.maxwidthTablet}px) {
        max-height: 200px;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`

const ArticleCard = ({ category, title, description, thumbnail, external_link, uid}) => {
    return(
        <>
    { external_link ? 
            <ArticleCardContainerExternal href={external_link.url} target="_blank" rel="noopener noreferrer">
                <ArticleCardContent className="ArticleCardContent">
                    <ArticleCardCategory>
                        {category[0].text}
                    </ArticleCardCategory>
                    <ArticleCardTitle>
                        {title[0].text}
                    </ArticleCardTitle>
                    <ArticleCardBlurb>
                        {RichText.render(description)}
                    </ArticleCardBlurb>
                    <ArticleCardAction className="ArticleCardAction">
                        Read the article <span>&#8594;</span>
                    </ArticleCardAction>
                </ArticleCardContent>
                <ArticleCardImageContainer className="ArticleCardImageContainer">
                    <img src={thumbnail.url} alt={title[0].text}/>
                </ArticleCardImageContainer>
            </ArticleCardContainerExternal>
        :
        (
        <ArticleCardContainer to={`/words/${uid}`}>
            <ArticleCardContent className="ArticleCardContent">
                <ArticleCardCategory>
                    {category[0].text}
                </ArticleCardCategory>
                <ArticleCardTitle>
                    {title[0].text}
                </ArticleCardTitle>
                <ArticleCardBlurb>
                    {RichText.render(description)}
                </ArticleCardBlurb>
                <ArticleCardAction className="ArticleCardAction">
                    Read the article <span>&#8594;</span>
                </ArticleCardAction>
            </ArticleCardContent>
            <ArticleCardImageContainer className="ArticleCardImageContainer">
                <img src={thumbnail.url} alt={title[0].text}/>
            </ArticleCardImageContainer>
        </ArticleCardContainer>
        )}
    </>
    )
}

export default ArticleCard;

ArticleCard.propTypes = {
    category: PropTypes.array.isRequired,
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array,
    external_link: PropTypes.object,
    uid: PropTypes.string.isRequired
}